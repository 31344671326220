import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { LeftOutlined, LoadingOutlined } from '@ant-design/icons';
import { Typography, Empty, Divider, Modal, Table, Spin } from 'antd';
import { useHistory } from 'react-router-dom';
import api from '../../api';
import moment from 'moment';
import { ContractForm } from '../../components';
import { useSelector } from 'react-redux';
export default function MyContract() {
  const history = useHistory();
  const { profile } = useSelector((state) => state._auth);
  const [contract, setContract] = useState({});
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    (async () => {
      setLoading(true);
      const { data } = await api.get('/bank');
      if (data.data.length > 0) setContract(data.data);
      setLoading(false);
    })();
  }, []);
  const [showDetail, setShowDetail] = useState(false);

  return (
    <motion.div
      style={{ padding: '10px 15px' }}
      initial={{ opacity: 0.3, y: 10 }}
      animate={{ opacity: 1, y: 0 }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          paddingBottom: 5,
          borderBottom: '1px solid #eee',
        }}
      >
        <motion.div
          whileTap={{ scale: 0.95, x: -10 }}
          onClick={() => history.replace('/')}
          style={{ padding: 0 }}
        >
          <LeftOutlined style={{ fontSize: 25, color: '#555' }} />
        </motion.div>
        <Typography.Text strong style={{ fontWeight: 700, fontSize: 20 }}>
          Khoản vay
        </Typography.Text>
        <div></div>
      </div>
      <div
        style={{
          padding: 10,
          display: 'block',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        {loading ? (
          <div
            style={{
              height: '80vh',
              width: '100vw',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
              background: 'rgba(0,0,0,0.07)',
            }}
          >
            <Spin indicator={<LoadingOutlined style={{ fontSize: 40 }} />} />
            <Typography.Text>Đang tải dữ liệu...</Typography.Text>
          </div>
        ) : (
          <>
            {!contract?._id&&false ? (
              <>
                <div>Danh sách thông tin ngân hàng trả nợ</div>
                <Empty description="Không có ngân hàng nào" />
                
              </>
            ) : (
              <>
                <Divider>
                  <div>Danh sách thông tin ngân hàng trả nợ</div>
                </Divider>
               
                <ModalDetailPayment contract={contract}/>
              </>
            )}
          </>
        )}
      </div>
    </motion.div>
  );
}
const ModalDetailPayment = ({ contract }) => {
  return (
    <div style={{ maxHeight: 350, overflowY: 'scroll',border:"1px #cccbcb8a solid",padding:"5px",color:"#000",fontWeight:"500"}}>
      {contract.map((item, index) => (
        <div key={index} style={{ marginBottom: 10 }}>
          <div style={{margin:"8px 10px"}}>
            <Typography.Text>Tên ngân hàng: </Typography.Text>
            <Typography.Text style={{float:'right'}}>{item.BankName}</Typography.Text>
          </div>
          <div style={{margin:"8px 10px"}}>
            <Typography.Text>Stk: </Typography.Text>
            <Typography.Text style={{float:'right'}}>{item.Stk}</Typography.Text>
          </div>
          <div style={{margin:"8px 10px"}}>
            <Typography.Text>Chủ khoản: </Typography.Text>
            <Typography.Text style={{float:'right'}}>{item.Owner}</Typography.Text>
          </div>
          <div style={{margin:"8px 10px"}}>
            <Typography.Text>Người đại diện: </Typography.Text>
            <Typography.Text style={{float:'right'}} >{item.Respresent}</Typography.Text>
          </div>
        </div>
      ))}
    </div>
  );
};